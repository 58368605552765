import React, { Fragment, useContext } from 'react';
import bg from '../../assets/images/about-us.jpg';
import bgRTL from '../../assets/images/about-us_rtl.jpg';
import { Description, Root, Title } from '../../components/@shared';
import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const AboutUs = () => {
  const { isRTL } = useContext(LanguageContext);

  return (
    <Fragment>
      <S.Root bg={isRTL ? bg : bgRTL} id="about_us">
        <S.Overlay isRTL={isRTL} />
        <S.MainBlock isRTL={isRTL}>
          <Title isRTL={isRTL}>
            <Text tid="ABOUT_US_TITLE" />
          </Title>
          <Description isRTL={isRTL}>
            <Text tid="ABOUT_US_DESCRIPTION1" />
            {<p />}
            <Text tid="ABOUT_US_DESCRIPTION2" />
            {<p />}
          <Text tid="ABOUT_US_DESCRIPTION3" />
          </Description>
        </S.MainBlock>
      </S.Root>
      <S.RootMobile isRTL={isRTL} id="about_us_mobile">
        <S.ContentContainerMobile>
          <S.TitleMobile isRTL={isRTL}>
            <Text tid="ABOUT_US_TITLE" />
          </S.TitleMobile>
          <S.DescriptionMobile isRTL={isRTL}><Text tid="ABOUT_US_DESCRIPTION1" />{<p />} 
          <Text tid="ABOUT_US_DESCRIPTION2" />{<p />} 
          <Text tid="ABOUT_US_DESCRIPTION3" /></S.DescriptionMobile>
        </S.ContentContainerMobile>
      </S.RootMobile>
    </Fragment>
  );
};

export default AboutUs;