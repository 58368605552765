import React from 'react';
import Spinner from '../Spinner';

import * as S from './styled';

const Button = ({style, label, onClick = () => {}, className, loading}) => {
  return (
    <S.Container loading={loading} className={className || ''} onClick={onClick} style={style}>
      {!loading ? <S.Label>{label}</S.Label> : <Spinner />}
    </S.Container>
  )
}

export default Button;