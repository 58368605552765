import styled from 'styled-components';

export const Container = styled.div`
  width: 240px;
  height: 68px;
  background: #EEB470;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2), inset 0px -3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({loading}) => loading ? 0.8 : 1};
  :hover {
    background: #E99C44;
  }
  :active {
    background: #E99C44;
  }
`;

export const Label = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
  @media (max-width: 960px) {
    font-size: 20px;
  }
`;