import styled from 'styled-components';
import mobileBg from '../../assets/images/about-us.jpg';

export const Root = styled.section`
  width: 100%;
  height: 950px;
  position: relative;
  background-image: ${({bg}) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  @media (max-width: 1200px) {
    height: 100vh;
  }
`;

export const MainBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-top: 250px;
  /* justify-content: center; */
  @media (max-width: 1200px) {
    padding-top:0;
    justify-content: center;
  }
`;

export const Text = styled.span`
  display: flex;
  flex-direction: ${({isRTL}) => isRTL ? 'row' : 'row-reverse'};
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const Title = styled.span`
  font-family: History Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const Overlay = styled.section`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: '100%';
  height: '100%';
  background: linear-gradient(89.65deg, #293E67 0.29%, rgba(41, 62, 103, 0.8) 50.51%, rgba(41, 62, 103, 0) 99.68%);
  mix-blend-mode: multiply;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;