import React, { useContext } from 'react';

import { languageOptions } from './languages';
import { LanguageContext } from './index';

import * as S from './styled';
export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const handleLanguageChange = e => userLanguageChange(e.target.value);

  return (
    <S.Selector
      onChange={handleLanguageChange}
      value={userLanguage}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <option style={{color: 'black'}} key={id} value={id}>{name}</option>
      ))}
    </S.Selector>
  );
};