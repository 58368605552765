import styled from 'styled-components';

export const Container = styled.footer`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #293E67;
`;

export const InfoContainer = styled.div`
  & > span {
    margin: 0 40px;
  }
  @media (max-width: 1200px) {
    & > span {
    margin: 0 12px;
    }
  }
`;

export const Text = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;
