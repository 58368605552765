import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import heroVideo from '../../assets/images/main_bg.mp4';
import GetDiscountModal from '../../components/@modals/GetDiscountModal';
import { Description, Title } from '../../components/@shared';
import Button from '../../components/Button';
import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const MainScreen = () => {
  const { isRTL } = useContext(LanguageContext);

  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <S.Root id="romantic">
      <GetDiscountModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        bundle="romantic"
      />
      <ReactPlayer
        url={heroVideo}
        playing
        autoPlay
        loop
        muted={true}
        playsinline={true}
        width="100%"
        height="100%"
      />
      <S.Overlay />
      <S.OverlayFull />
      <S.MainBlock isRTL={isRTL}>
        <Title isRTL={isRTL} className="main-title"><Text tid="MAIN_TITLE" /></Title>
        <Description isRTL={isRTL} className="main-description" marginBottom={40}>
          <Text tid="MAIN_DESCRIPTION1" />
        </Description>
        <Description isRTL={isRTL}>
         <Text tid="MAIN_DESCRIPTION2" /> <br />
          <u><Text tid="MAIN_DESCRIPTION3" /></u> <br />
          <Text tid="MAIN_DESCRIPTION4" />
        </Description>
        <S.ButtonContainer isRTL={isRTL}>
          <Button onClick={() => setModalOpen(true)} style={{marginTop: 40, width: 316}} label={<Text tid="BUTTON_TEXT" />}  />
        </S.ButtonContainer>
        <S.MobileButtonContainer>
          <Button onClick={() => setModalOpen(true)} style={{marginTop: 20, width: 316}} label={<Text tid="BUTTON_TEXT" />}  />
        </S.MobileButtonContainer>
      </S.MainBlock>
    </S.Root>
  );
};

export default MainScreen;