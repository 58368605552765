import styled from 'styled-components';

export const Container = styled.input`
  width: 220px;
  height: 60px;
  outline: none;
  background: #F2F2F2;
  backdrop-filter: blur(14px);
  border-radius: 8px;
  border-color: #E53C3C;
  border-style: solid;
  border-width: ${({error}) => error ? 1 : 0};
  font-size: 24px;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
  padding: 0 40px;
  color: #293E67;
  margin-top: 35px;
  text-align: center;
  ::placeholder {
    color: #293E67;
    opacity: 0.4;
  }
  @media (max-width: 960px) {
    ::placeholder {
      font-size: 16px;
    }
  }
`;

export const Error = styled.div`
  margin-top: 4px;
  color: #E53C3C;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media (max-width: 960px) {
    font-size: 12px;
  }
`;