import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 640px;
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    .submit-button {
      height: 62px;
    }
    .phone-input {
      width: 320px;
      height: 62px;
      margin-top: 0;
      background-color: white;
      margin-right: 20px;
      .select-value {
        font-size: 24px;
      }
      .select-menu {
        width: 87px;
        background-color: white;
      }
    }
    @media (max-width: 1200px) {
      height: 520px;
      .submit-button {
        height: 59px;
        width: 298px;
        margin-top: 20px;
      }
      .phone-input {
        height: 48px;
        width: 304px;
        margin-right: 0;
        .select-value {
        font-size: 16px;
        }
        .select-menu {
          width: 73px;
          background-color: white;
        }
      }
    }
`;

export const Title = styled.span`
  font-family: History Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.04em;
  color: #293E67;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const Description = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #293E67;
  max-width: 720px;
  margin-top: 40px;
  @media (max-width: 1200px) {
    font-size: 16px;
    margin-top: 32px;
    padding: 0 16px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: ${({isRTL}) => isRTL ? 'row': 'row-reverse'};
  margin-top: 40px;
  .mobile-input {
    margin-right: 20px;
  }
  .phone-input {
    margin-left: ${({isRTL}) => !isRTL ? 20 : 0}px;
  }
  & > div {
      width: 324px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    .mobile-input {
      margin-bottom: 20px;
      margin-right: 0px !important;
      height: 40px;
      width: 280px;
    }
    .phone-input {
      height: 40px;
      width: 300px;
      margin-left: 0;
    }
    & > div {
      height: 59px;
      width: 280px;
    }
  }
`;