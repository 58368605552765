import { useContext, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-down.svg';
import { LanguageContext } from '../../translation';

import * as S from './styled';

const PhoneInput = ({value, onChange, country, onCountryChange, className = '', error}) => {

  const { isRTL } = useContext(LanguageContext);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const onSelect = (value) => {
    onCountryChange(value);
    setMenuOpen(false);
  };

  const validate = (evt) => {
    let theEvent = evt || window.event;
  
    let key;
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const onInputChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <span>
      <S.Container isRTL={isRTL} error={error} className={className}>
        <S.SelectValue className="select-value" onClick={() => setMenuOpen(!isMenuOpen)}>
          {country}
          <Arrow />
        </S.SelectValue>
        <S.Input isRTL={isRTL} maxLength={7} onKeyPress={validate} value={value} onChange={onInputChange} placeholder="XXX-ХХ-XX" />
        {isMenuOpen ? <S.OptionsMenu className="select-menu">
        {['050', '051', '052', '053','054', '055','056', '057','058', '059'].map((code) => (
          <S.Option onClick={() => onSelect(code)} key={code}>
            {code}
          </S.Option>
        ))}
        </S.OptionsMenu> : null}
      </S.Container>
      {error ? <S.Error>{error}</S.Error> : null}
    </span>
  )
}

export default PhoneInput;