import React, { Fragment, useContext, useState } from 'react';
import bg from '../../assets/images/slideshow3_mobile.jpg';
import GetDiscountModal from '../../components/@modals/GetDiscountModal';
import { Description, Title } from '../../components/@shared';
import Button from '../../components/Button';
import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const TrainingScreen = () => {
  const { isRTL } = useContext(LanguageContext);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Fragment>
      <GetDiscountModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        bundle="training"
      />
      <S.Root isRTL={isRTL} id="training">
        <S.MainBlock isRTL={isRTL}>
          <Title isRTL={isRTL}><Text tid="TRAINING_TITLE" /></Title>
          <Description isRTL={isRTL} marginBottom={40}>
            <Text tid="TRAINING_DESCRIPTION1" />
          </Description>
          <Description isRTL={isRTL}>
          <Text tid="TRAINING_DESCRIPTION2" /> {<p />}
          <u><Text tid="TRAINING_DESCRIPTION3" /></u><br />
            <Text tid="TRAINING_DESCRIPTION4" />
          </Description>
          <Button onClick={() => setModalOpen(true)} style={{marginTop: 40, width: 316, alignSelf: !isRTL ? 'flex-end' : 'flex-start'}} label={<Text tid="TRAINING_RENTALS_TEXT" />}  />
        </S.MainBlock>
        <S.RightBlock>
          <S.ImageContainer>
            <S.Image src={bg} />
          </S.ImageContainer>
        </S.RightBlock>
      </S.Root>
      <S.RootMobile id="training_mobile">
        {/* <S.BgContainerMobile isRTL={isRTL} /> */}
        <S.BgContainerMobile isRTL />
        <S.ContentContainerMobile>
          <S.TitleMobile isRTL={isRTL}>
            <Text tid="TRAINING_TITLE" />
          </S.TitleMobile>
          <S.DescriptionMobile isRTL={isRTL}>
            <Text tid="TRAINING_DESCRIPTION1" />
          {<p />}
          <Text tid="TRAINING_DESCRIPTION2" /> {<br />}
          <u><Text tid="TRAINING_DESCRIPTION3" /></u> {<br />}
          <Text tid="TRAINING_DESCRIPTION4" />
          </S.DescriptionMobile>
          <Button onClick={() => setModalOpen(true)} style={{width: 272, alignSelf: 'center', marginTop: 32}} label={<Text tid="TRAINING_RENTALS_TEXT" />} />
        </S.ContentContainerMobile>
      </S.RootMobile>
    </Fragment>
  );
};

export default TrainingScreen;