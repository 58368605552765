import styled from 'styled-components';

import bg from '../../assets/images/main_bg-0.jpg';

export const Root = styled.section`
    height: 950px;
    position: relative;
    video {
        object-fit: cover;
    }
    @media (max-width: 500px) {
        video {
            object-position: -500px center;
        }
    }
    @media (max-width: 400px) {
        height: 650px;
        background-image: url(${bg});
        background-position: center;;
        video {
            object-position: -350px center;
        }
        .main-title {
            margin-bottom: 20px;
        }
        .main-description {
            margin-bottom: 20px;
        }
    }
`;

export const ButtonContainer = styled.div`
    position: absolute;
    position: absolute;
    bottom: 280px;
    right: ${({isRTL}) => isRTL ? '-200px' : 'unset'};
    left: ${({isRTL}) => !isRTL ? '-200px' : 'unset'};
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const MobileButtonContainer = styled.div`
    display: none;
    @media (max-width: 1200px) {
        display: block;
    }
`;

export const MainBlock = styled.div`
  position: absolute;
  top: 0;
  right: ${({isRTL}) => isRTL ? '50%' : 0};
  left: ${({isRTL}) => !isRTL ? '50%' : 0};
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${({isRTL}) => isRTL ? 100 : 0}px;
  padding-right: ${({isRTL}) => !isRTL ? 100 : 0}px;
  @media (max-width: 1200px) {
    right: 0;
    left: 0;
    padding-left: 0;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 96px;
    div {
        width: 272px !important;
    }
    }
`;

export const Overlay = styled.section`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    background: linear-gradient(89.65deg, #82A2B6 45.85%, rgba(130, 162, 183, 0) 99.68%);
    mix-blend-mode: normal;
    opacity: 0.5;
    @media (max-width: 1200px) {
      display: none;
    }
`;

export const OverlayFull = styled.section`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #82A2B6;
    mix-blend-mode: multiply;
    opacity: 0.4;
`;