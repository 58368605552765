import * as S from './styled';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { useEffect, useState } from 'react';
import MobileCallButton from '../MobleCallButton';

const HeaderMobile = ({toggleMenu, isOpen}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setScrolled(true);
    } else if (window.scrollY > 50) {
      return;
    } else if (window.scrollY < 50) {
      setScrolled(false);
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);
  
  return (
    <S.Container scrolled={scrolled}>
      <Logo className="logo-mobile" />
      <MobileCallButton />
      <S.Row>
        <S.MobileButton onClick={() => toggleMenu(!isOpen)}>
          <div id="nav-icon" className={isOpen ? "open" : null}> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
        </S.MobileButton>
      </S.Row>
    </S.Container>
  )
}

export default HeaderMobile;