import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(41, 62, 103, 0.5);
  backdrop-filter: blur(40px);
  z-index: 11111;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  width: ${({sizes}) => sizes.width}px;
  height: ${({sizes}) => sizes.height}px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  .name-input {
    background-color: #F2F2F2;
    margin-top: 16px;
  }
  .submit-button {
    width: 250px;
    margin-top: 40px;
    /* margin-bottom: 80px; */
  }
  @media (max-width: 960px) {
    width: 300px;
    height: initial;
    .name-input {
      width: 240px;
      height: 40px;
      box-sizing: border-box;
    }
    .phone-input {
      width: 240px;
      height: 40px;
      .select-value {
        font-size: 16px;
      }
      .select-menu {
        width: 73px;
      }
    }
    .submit-button {
      width: 240px;
      margin-top: 32px;
      margin-bottom: 60px;
      height: 58px;
    }
  }
`;

export const Title = styled.span`
  font-family: History Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  color: #293E67;
  margin-top: 60px;
  @media (max-width: 960px) {
    font-size: 24px;
    margin-top: 60px;
  }
`;

export const Description = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #293E67;
  margin: 12px 0px;
  @media (max-width: 960px) {
    font-size: 16px;
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  cursor: pointer;
  border: 1px solid rgba(162, 170, 186, 0.3);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    width: 28px;
    height: 28px;
    top: 12px;
    right: 12px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const NotRecieveText = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #293E67;
`;

export const ContactContainer = styled.div`
  display: flex;
  margin-top: 28px;
  align-items: center;
`;

export const ContactNumber = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  color: #293E67;
  @media (max-width: 960px) {
    font-size: 24px;
  }
`;

export const ContactIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: linear-gradient(180deg, #69B2D2 0%, #35669F 100%);
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;