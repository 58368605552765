import React, { Fragment, useContext, useState } from 'react';

import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as XMark } from '../../../assets/icons/x-mark.svg';

import Button from '../../Button';
import Input from '../../Input';
import PhoneInput from '../../PhoneInput';
import CodeInput from '../../CodeInput';

import TokenStorage from '../../../services/storage/token';
import { uuidv4 } from '../../../utils';
import http from '../../../services/http';

import * as S from './styled';
import { useHistory } from 'react-router-dom';

import { LanguageContext, Text } from '../../../translation';

const modalSizes = {
  1: {
    width: 600,
    height: 511,
  },
  2: {
    width: 600,
    height: 529,
  },
  3: {
    width: 600,
    height: 417,
  }
};


const initialCountry = '050';

const GetDiscountModal = ({onClose, isOpen, bundle = ''}) => {
  const { isRTL } = useContext(LanguageContext);
  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState(initialCountry);

  const [errors, setErrors] = useState({
    name: '',
    phoneNumber: '',
    code: '',
  });

  const [formId, setFormId] = useState(null);
  const [code, setCode] = useState('');

  const history = useHistory();

  const validate = (fields = []) => {
    let isValid = true;
    let errorsObj = {};
    fields.forEach(field => {
      if (field === 'name') {
        if (!name) {
          errorsObj.name = isRTL ? 'Required' : 'נדרש'
          isValid = false;
        }
      }
      if (field === 'phoneNumber') {
        if (phoneNumber.length < 7) {
          errorsObj.phoneNumber = isRTL ? 'Min length 7' : 'אורך מינימלי 7'
          isValid = false;
        }
      }
      if (field === 'code') {
        if (code.length < 4 || code.includes('_')) {
          errorsObj.code = isRTL ? 'Min length 4' : 'אורך מינימלי 4'
          isValid = false;
        }
      }
    })
    setErrors({...errorsObj});
    return isValid;
  };

  const resetToFirstStep = () => {
    setName('');
    setPhoneNumber('');
    setCountry(initialCountry);
    setFormId(null);
    setCode('');
    resetErrors();
    setStep(1);
  };

  const resetErrors = () => {
    setErrors({
      name: '',
      phoneNumber: '',
      code: '',
    })
  };

  if (!isOpen) {
    return null;
  }

  const close = () => {
    setStep(1);
    setName('');
    setPhoneNumber('');
    setCountry(initialCountry);
    setFormId(null);
    setCode('');
    onClose();
    resetErrors();
  }

  const onCloseModal = (e) => {
    if (e.target.id === "modal-container") {
      close();
    }
  };

  const onSubmit = async () => {
    try {
      resetErrors();
      if (step === 1) {
        const isValid = validate(['name', 'phoneNumber']);
        if (!isValid) {
          return;
        }
        let token = TokenStorage.get();
        if (!token) {
          token = uuidv4();
          TokenStorage.save(token);
        }
        setLoading(true);
        const phone = `+9725${country[2]}${phoneNumber}`;
        const {data} = await http.post('/forms', {
          name: name,
          phone: phone,
          token: token,
          bundle: bundle,
        });
        setFormId(data.formId);
        if (data.returning) {
          close();
          history.push('/thank');
        }
        setStep(2);
      }
      if (step === 2) {
        const isValid = validate(['code']);
        if (!isValid) {
          return;
        }
        setLoading(true);
        const {data} = await http.post('/forms/checkconfirmationcode', {
          formId: formId,
          code: code.replace(/\s/g, ''),
        });
        if (data) {
          close();
          history.push('/thank')
        } else {
          setStep(3);
        }
      }
    } catch (err) {
      setStep(3);
    }
    setLoading(false);
  };

  const contentDependStep = () => {
    if (step === 1) {
      return (
        <Fragment>
          <S.Title>
            <Text tid="to get a discount" />
          </S.Title>
          <S.Description>
            <Text tid="fill your name and phone number" />
          </S.Description>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="name-input"
            placeholder={isRTL ? 'Name' : 'שם פרטי'}
            error={errors.name}
          />
          <PhoneInput
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            country={country}
            onCountryChange={setCountry}
            className="phone-input"
            error={errors.phoneNumber}
          />
          <Button loading={loading} onClick={onSubmit} className="submit-button" label={<Text tid="Get a discount" />} />
        </Fragment>
      )
    }
    if (step === 2) {
      return (
        <Fragment>
          <S.Title>
            <Text tid="enter 4-digit code" />
          </S.Title>
          <S.Description><Text tid="We have sent to" /> {country}{phoneNumber} <br /> <Text tid="to confirm your phone number" /></S.Description>
          <CodeInput error={errors.code} value={code} onChange={e => setCode(e.target.value)} />
          <Button loading={loading} style={{marginBottom: 24}} onClick={onSubmit} className="submit-button" label={<Text tid="Enter" />} />
          <S.NotRecieveText>
            <Text tid="Haven't received the code?" />
          </S.NotRecieveText>
          <S.NotRecieveText onClick={() => setStep(1)} style={{cursor: 'pointer', marginBottom: 30}}>
            <u><Text tid="Change your phone number" /></u>
          </S.NotRecieveText>
        </Fragment>
      )
    }
    if (step === 3) {
      return (
        <Fragment>
        <S.Title>
          <Text tid="The code is incorrect" />
        </S.Title>
        <Button onClick={resetToFirstStep} className="submit-button" label={<Text tid="Send Again" />} />
        </Fragment>
      )
    }
  }

  return (
    <S.Container id="modal-container" onClick={onCloseModal}>
      <S.ModalContainer sizes={modalSizes[step]}>
        <S.CloseButton onClick={close}>
          <XMark />
        </S.CloseButton>
        {contentDependStep()}
      </S.ModalContainer>
    </S.Container>
  )
}

export default GetDiscountModal;