import axios from 'axios';

const baseURL = 'https://iflight-api.azurewebsites.net';

const http = axios.create({
  withCredentials: false,
  baseURL,
  headers: {'Content-Type': 'application/json'},
});

export default http;
