import React from 'react';

import * as S from './styled';

const Footer = () => {
  return (
    <S.Container>
      <S.InfoContainer>
        <S.Text>©2021 iFlight</S.Text>
        <S.Text>info@iflight.io</S.Text>
      </S.InfoContainer>
    </S.Container>
  )
}

export default Footer;