import React, { Fragment, useContext, useState } from 'react';
import bg from '../../assets/images/slideshow1.jpg';
import bgRTL from '../../assets/images/slideshow1_rtl.jpg';
import GetDiscountModal from '../../components/@modals/GetDiscountModal';
import { Description, Root, Title } from '../../components/@shared';
import Button from '../../components/Button';
import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const AboutAircraftScreen = () => {
  const { isRTL } = useContext(LanguageContext);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Fragment>
      <GetDiscountModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        bundle="about_aircraft"
      />
      <S.Root bg={isRTL ? bg : bgRTL} id="about_aircraft">
        <S.Overlay isRTL={isRTL} />
        <S.MainBlock isRTL={isRTL}>
          <Title isRTL={isRTL}>
            <Text tid="ABOUT_AIRCRAFT_TITLE" />
          </Title>
          <Description isRTL={isRTL} marginBottom={40}>
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION1" /><p />
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION2" />
          </Description>
          <Description isRTL={isRTL}>
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION3" />{<p />}
            <u><Text tid="ABOUT_AIRCRAFT_DESCRIPTION4" /></u><br />
            <Text tid="ABOUT_AIRCRAFT_DESCRIPTION5" />
          </Description>
          <Button onClick={() => setModalOpen(true)} style={{marginTop: 40, width: 316, alignSelf: !isRTL ? 'flex-end' : 'flex-start'}} label={<Text tid="ABOUT_AIRCRAFT_BUTTON_TEXT" />}  />
        </S.MainBlock>
      </S.Root>
      <S.RootMobile id="about_aircraft_mobile">
        {/* <S.BgContainerMobile isRTL={isRTL} /> */}
        <S.BgContainerMobile isRTL={true} />
        <S.ContentContainerMobile>
          <S.TitleMobile isRTL={isRTL}>
            <Text tid="ABOUT_AIRCRAFT_TITLE" />
          </S.TitleMobile>
          <S.DescriptionMobile isRTL={isRTL}>
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION1" />
          {<p />}
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION2" />
          {<p />}
          <Text tid="ABOUT_AIRCRAFT_DESCRIPTION3" />
          {<p />}
          <u><Text tid="ABOUT_AIRCRAFT_DESCRIPTION4" /></u> {<br />}
            <Text tid="ABOUT_AIRCRAFT_DESCRIPTION5" />
          </S.DescriptionMobile>
          <Button onClick={() => setModalOpen(true)} style={{width: 272, alignSelf: 'center', marginTop: 32}} label={<Text tid="ABOUT_AIRCRAFT_BUTTON_TEXT" />} />
        </S.ContentContainerMobile>
      </S.RootMobile>
    </Fragment>
  );
};

export default AboutAircraftScreen;