import React, { Fragment, PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import CallButton from '../components/CallButton';

import AboutAircraft from '../screens/AboutAircraft';
import AboutUs from '../screens/AboutUs';
import Main from '../screens/Main';
import Rentals from '../screens/Rentals';
import RequestCallback from '../screens/RequestCallback';
import Training from '../screens/Training';
import Thank from '../screens/Thank';
import Footer from '../components/Footer';


const Module = () => {
  return (
    <Fragment>
      <Main />
      <Rentals />
      <Training />
      <AboutAircraft />
      <AboutUs />
      <RequestCallback />
      <CallButton />
      <Footer />
    </Fragment>
  )
};

class MainModule extends PureComponent {

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Module} />
        <Route exact path="/thank" component={Thank} />
      </Switch>
    );
  }
}

export default MainModule;
