import styled from 'styled-components';

export const LanguagesRow = styled.span`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LanguageValue = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: ${({selected}) => !selected ? '#FFFFFF' : '#EEB470'};
  border-bottom: ${({selected}) => selected ? '1px solid #EEB470' : 'none'};
`;

export const Selector = styled.select`
  background-color: transparent;
  border: none;
  color: white;
  width: 50px;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  outline: none;
  cursor: pointer;
`;