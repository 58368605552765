import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 90px);
  background-color: #293E67;
  z-index: 999;
  top: 90px;
  left: 100%;
  transition: 0.5s;
  &.open {
      left: 0;
      transition: 0.5s;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

export const MenuItem = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: ${({selected}) => !selected ? '#FFFFFF' : '#EEB470'};
  margin-bottom: 40px;
`;

export const LanguagesRow = styled.span`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LanguageValue = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: ${({selected}) => !selected ? '#FFFFFF' : '#EEB470'};
  border-bottom: ${({selected}) => selected ? '1px solid #EEB470' : 'none'};
`;