import React, { useContext } from 'react';
import InputMask from 'react-input-mask';

import { LanguageContext } from '../../translation';

import * as S from './styled';

const CodeInput = ({value = '', onChange = () => {}, error}) => {
  const { isRTL } = useContext(LanguageContext);
  return (
    <span>
      <InputMask mask="9 9 9 9" value={value} onChange={onChange}>
        {(inputProps) => <S.Container error={error} {...inputProps} placeholder="X - X - X - X" type="tel" disableUnderline />}
      </InputMask>
      {error ? <S.Error>{error}</S.Error> : null}
    </span>
  )
}

export default CodeInput;