import React, { Fragment, useContext } from 'react';
import bg from '../../assets/images/thankBg.png';
import whatsappIcon from '../../assets/images/whatsapp.png';

import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const Thank = () => {
  const { isRTL } = useContext(LanguageContext);

  const onWhatsappClick = () => {
    const isMobile = ( window.innerWidth <= 900 );
    if (!isMobile) {
      window.open("https://wa.me/972546440526",'_blank');
    } else {
      window.open("whatsapp://send?phone=+972546440526",'_blank');
    }
  };

  return (
    <Fragment>
      <S.Root bg={bg}>
        <S.Overlay />
        <S.MainBlock>
          <S.Title>
            <Text tid="THANK_TITLE" />
          </S.Title>
          <S.Text>
            <Text tid="We will be in touch with you soon!" />
          </S.Text>
          <S.Text isRTL={isRTL} onClick={onWhatsappClick} style={{cursor: 'pointer'}}><Text tid="or contact us on" /> <img src={whatsappIcon} style={{marginLeft: 10, marginRight: 10}} alt="whatsapp_icon" /> <u><Text tid="whatsapp chat" /></u> </S.Text>
        </S.MainBlock>
      </S.Root>
    </Fragment>
  );
};

export default Thank;