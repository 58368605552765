import React, { useContext } from 'react';

import { languageOptions } from './languages';
import { LanguageContext } from './index';

import * as S from './styled';

const LanguageSelectorMobile = () => {

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const handleLanguageChange = value => userLanguageChange(value);

  return (
    <S.LanguagesRow>
      {Object.entries(languageOptions).map(([id, name], index) => (
        <S.LanguageValue onClick={() => handleLanguageChange(id)} style={{marginRight: index === 0 ? 30 : 0}} selected={id === userLanguage}>{name}</S.LanguageValue>
      ))}
    </S.LanguagesRow>
  )
}

export default LanguageSelectorMobile;