import styled from 'styled-components';
import bg from '../../assets/images/slideshow3_mobile.jpg';
import mobileBg from '../../assets/images/slideshow3_mobile.jpg';

export const Root = styled.section`
  width: 100%;
  height: 950px;
  position: relative;
  background: #71C2E5;
  display: flex;
  flex-direction: ${({isRTL}) => isRTL ? 'row' : 'row-reverse'};
  @media (max-width: 1200px) {
      display: none;
  }
`;

export const MainBlock = styled.div`
  background-color: transparent;
  z-index: 2;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-right: ${({isRTL}) => isRTL ? 0 : 100}px;
  padding-left: ${({isRTL}) => !isRTL ? 0 : 100}px;
`;

export const RightBlock = styled.div`
  width: 940px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({isRTL}) => isRTL ? 60 : 20}px;
  padding-left: ${({isRTL}) => !isRTL ? 60 : 20}px;
  @media (max-width: 1600px) {
    width: 720px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 50%;
  background-color: rgba(41, 62, 103, 0.1);
  padding: 60px;
  @media (max-width: 1600px) {
   padding: 40px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
`;

export const RootMobile = styled.section`
    display: none;
    flex-direction: column;
    @media (max-width: 1200px) {
        display: flex;
    }
`;

export const BgContainerMobile = styled.div`
    display: none;
    width: 100%;
    height: 289px;
    background-image: url(${mobileBg});
    background-size: cover;
    background-position: center;
    transform: ${({isRTL}) => isRTL ? 'none' : 'scaleX(-1)'};
    @media (max-width: 1200px) {
        display: block;
    }
`;

export const ContentContainerMobile = styled.div`
    display: none;
    padding: 20px 0 40px 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #71C2E5;
    @media (max-width: 1200px) {
        display: flex;
    }
`;

export const TitleMobile = styled.span`
    font-family: History Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-decoration-line: underline;
    color: #FFFFFF;;
    margin: 20px 0px;
    padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
    padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px;
    text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
    line-height: 31px;
`;

export const DescriptionMobile = styled.span`
    font-family: Aeroport;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
    padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px;
    text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
    direction: ${({isRTL}) => isRTL ? 'ltr' : 'rtl'};
`;