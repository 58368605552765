import { Fragment, useContext, useState } from 'react';
import WhatsAppModal from '../@modals/WhatsAppModal';

import { LanguageContext } from '../../translation';

import './call.css';

const CallButton = () => {
  const { isRTL } = useContext(LanguageContext);
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <Fragment>
      <a style={{left: !isRTL ? '93px' : 'unset', right: isRTL ? '93px' : 'unset'}} onClick={() => setModalOpen(true)} id="calltrap-btn"><div id="calltrap-ico"></div></a>
      <WhatsAppModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        bundle="whatsapp"
      />
    </Fragment>
  )
}

export default CallButton;