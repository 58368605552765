import * as S from './styled';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LanguageSelector from '../../translation/LanguageSelector';
import { LanguageContext, Text } from '../../translation/index';

const options = [
  {
    label: <Text tid="ROMANTIC FLIGHTS" />,
    id: 'romantic',
  },
  {
    label: <Text tid="RENTALS" />,
    id: 'rentals',
  },
  {
    label: <Text tid="TRAINING" />,
    id: 'training',
  },
  {
    label: <Text tid="ABOUT AIRCRAFT" />,
    id: 'about_aircraft',
  },
  {
    label: <Text tid="ABOUT US" />,
    id: 'about_us',
  }
];

const MenuItem = ({ label, active, id, startId }) => {
  
  const [anchorTarget, setAnchorTarget] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setAnchorTarget(document.getElementById(id));
  }, [id, location]);

  const handleClick = (event, sectionId) => {
    if (location.pathname === '/thank') {
      history.push('/');
      return;
    }
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <S.MenuItem
      onClick={(e) => handleClick(e, id)}
      selected={active === id && id !== startId}
    >
      {label}
    </S.MenuItem>
  )
};

const Header = () => {
  const { isRTL } = useContext(LanguageContext);
  const [scrolled, setScrolled] = useState(false);
  const [active, setActive] = useState(options[0].id);

  const height = 950;
  const screenHeight = height - 60;

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 0 && window.scrollY < screenHeight) {
        setActive(options[0].id)
      }
      if (window.scrollY >= screenHeight && window.scrollY < (screenHeight * 2)) {
        setActive(options[1].id);
      }
      if (window.scrollY >= (screenHeight * 2) && window.scrollY < (screenHeight * 3)) {
        setActive(options[2].id);
      }
      if (window.scrollY >= (screenHeight * 3) && window.scrollY < (screenHeight * 4)) {
        setActive(options[3].id);
      }
      if (window.scrollY >= (screenHeight * 4)) {
        setActive(options[4].id);
      }
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, [screenHeight]);

  const handleActive = (id) => setActive(id);

  return (
    <S.Container isRTL={isRTL} scrolled={scrolled}>
      <Logo />
      <S.Menu isRTL={isRTL}>
        {
          options.map(option => (
            <MenuItem
              startId={options[0].id}
              key={option.id}
              active={active}
              label={option.label}
              id={option.id}
              handleActive={handleActive}
            />
          ))
        }
        <LanguageSelector />
      </S.Menu>
    </S.Container>
  )
}

export default Header;