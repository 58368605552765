import React from 'react';

import './index.css';

const Spinner = () => {
  return (
    <div class="loader">Loading...</div>
  )
}

export default Spinner;