import { Fragment, useState } from 'react';
import WhatsAppModal from '../@modals/WhatsAppModal';
import './call.css';

const MobileCallButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <Fragment>
    <WhatsAppModal
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      bundle="whatsapp"
      isMobile
    />
    <a onClick={() => setModalOpen(true)} id="mobile-calltrap-btn"><div id="mobile-calltrap-ico"></div></a>
    </Fragment>
  )
}

export default MobileCallButton;