import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  background-color: ${({scrolled}) => scrolled ? '#293E67' : 'transparent'};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;
  display: none;
  height: 96px;
  justify-content: space-between;
  align-items: center;
  .logo-mobile {
    width: 173px;
    height: 60px;
    margin-left: 12px;
    transition: all 200ms;
  }
  transition: all 200ms;
  @media (max-width: 1215px) {
    display: flex;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const MobileButton = styled.div`
  display: block;
  margin-right: 12px;
  width: 40px;
  height: 36px;
  position: relative;
  #nav-icon {
  }
  #nav-icon span {
      display: block;
      position: absolute;
      height: 4px;
      width: 50%;
      background: white;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
  }
  #nav-icon span:nth-child(even) {
      left: 50%;
  }
  #nav-icon span:nth-child(odd) {
      left: 0px;
  }
  #nav-icon span:nth-child(1),
  #nav-icon span:nth-child(2) {
      top: 0px;
  }
  #nav-icon span:nth-child(3),
  #nav-icon span:nth-child(4) {
      top: 12px;
  }
  #nav-icon span:nth-child(5),
  #nav-icon span:nth-child(6) {
      top: 24px;
  }
  #nav-icon.open span:nth-child(1),
  #nav-icon.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
  }
  #nav-icon.open span:nth-child(2),
  #nav-icon.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }
  #nav-icon.open span:nth-child(1) {
      left: 5px;
      top: 13px;
  }
  #nav-icon.open span:nth-child(2) {
      left: calc(50% - 3px);
      top: 13px;
  }
  #nav-icon.open span:nth-child(3) {
      left: -50%;
      opacity: 0;
  }
  #nav-icon.open span:nth-child(4) {
      left: 100%;
      opacity: 0;
  }
  #nav-icon.open span:nth-child(5) {
      left: 5px;
      top: 25px;
  }
  #nav-icon.open span:nth-child(6) {
      left: calc(50% - 3px);
      top: 25px;
  }
`;