import React, { Fragment, useContext, useState } from 'react';

import ChatWithUsModal from '../../components/@modals/ChatWithUsModal';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PhoneInput from '../../components/PhoneInput';
import { LanguageContext, Text } from '../../translation';

import * as S from './styled';

const RequestCallback = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('050');
  const [name, setName] = useState('');

  const { isRTL } = useContext(LanguageContext);

  return (
    <Fragment>
      <ChatWithUsModal
        isOpen={isModalOpen}
        initialPhoneNumber={phoneNumber}
        initialCountryState={country}
        initialName={name}
        onClose={() => setModalOpen(false)}
        bundle="callback"
      />
      <S.Container>
        <S.Title>
          <Text tid="REQUEST_TITLE" />
        </S.Title>
        <S.Description>
          <Text tid="REQUEST_DESCRIPTION" />
        </S.Description>
        <S.InputContainer isRTL={isRTL}>
          <Input
            className="mobile-input"
            value={name}
            onChange={e => setName(e.target.value)}
            style={{marginRight: 20}}
            placeholder={isRTL ? 'Name' : 'שם פרטי'}
          />
          <PhoneInput
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            country={country}
            onCountryChange={setCountry}
            className="phone-input"
          />
          <Button className="submit-button" onClick={() => setModalOpen(true)} label={<Text tid="REQUEST_BUTTON_TEXT" />} />
        </S.InputContainer>
      </S.Container>
    </Fragment>
  )
}

export default RequestCallback;