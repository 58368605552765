import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  padding-top: ${({scrolled}) => scrolled ? 20 : 58}px;
  padding-bottom: ${({scrolled}) => scrolled ? 20 : 0}px;
  background-color: ${({scrolled}) => scrolled ? '#293E67' : 'transparent'};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;
  display: flex;
  flex-direction: ${({isRTL}) => isRTL ? 'row': 'row-reverse'};
  justify-content: space-between;
  svg {
    margin-left: ${({scrolled, isRTL}) => isRTL ? (scrolled ? 60 : 100) : 0}px;
    margin-right: ${({scrolled, isRTL}) => !isRTL ? (scrolled ? 60 : 100) : 0}px;
    width: ${({scrolled}) => scrolled ? 231 : 362}px;
    height: ${({scrolled}) => scrolled ? 80 : 125}px;
    transition: all 200ms;
  }
  transition: all 200ms;
  @media (max-width: 1215px) {
    display: none;
  }
  @media (max-width: 1500px) {
    svg {
      margin-left: ${({scrolled}) => scrolled ? 60 : 40}px;
      width: 231px;
      height: 80px;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: ${({isRTL}) => isRTL ? 'row' : 'row-reverse'};
  align-items: center;
  span:not(:last-child) {
    margin-right: ${({isRTL}) => isRTL ? 70 : 0}px;
    margin-left: ${({isRTL}) => !isRTL ? 70 : 0}px;
  }
  margin-right: ${({isRTL}) => isRTL ? 100 : 0}px;
  margin-left: ${({isRTL}) => !isRTL ? 100 : 0}px;
  @media (max-width: 1500px) {
    margin-right: 20px;
  }
`;

export const MenuItem = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: ${({selected}) => !selected ? '#FFFFFF' : '#EEB470'};
`;
