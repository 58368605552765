import styled from 'styled-components';

export const Title = styled.p`
  font-family: History Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 2.4vw;
  letter-spacing: 0.04em;
  text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
  text-decoration-line: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  text-decoration-thickness: 1px;
  text-transform: uppercase;
  text-underline-offset: 5px;
  color: #FFFFFF;
  margin-bottom: 40px;
  line-height: 3vw;
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

export const Description = styled.span`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  color: #FFFFFF;
  direction: ${({isRTL}) => isRTL ? 'ltr' : 'rtl'};
  text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
  margin-bottom: ${({marginBottom}) => marginBottom || 0}px;
  @media (max-width: 1200px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Root = styled.section`
  width: 100%;
  height: 950px;
  position: relative;
  background-image: ${({bg}) => `url(${bg})`};
  background-size: cover;
  background-position: center center;
  @media (max-width: 1200px) {
    display: none;
  }
`;