import React, { Fragment, useContext } from 'react';
import { LanguageContext } from '../../translation';

import * as S from './styled';

const Input = ({style, placeholder, className = '', value = '', onChange = () => {}, error}) => {
  const { isRTL } = useContext(LanguageContext);
  return (
    <span>
      <S.Container
        error={error}
        onChange={onChange}
        value={value}
        className={className}
        placeholder={placeholder}
        style={style}
        isRTL={isRTL}
      />
      {error ? <S.Error>{error}</S.Error> : null}
    </span>
  )
}

export default Input;