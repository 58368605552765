/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import MainModule from './router/main';

import Footer from './components/Footer';
import Header from './components/Header';
import HeaderMobile from './components/HeaderMobile';
import MobileMenu from './components/MobileMenu.js';

import TokenStorage from './services/storage/token';
import http from './services/http';
import { uuidv4 } from './utils';
import { LanguageProvider } from './translation';

const App = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(async () => {
        const {data: ipInfo} = await axios.get('https://geolocation-db.com/json/');
        let token = TokenStorage.get();
        if (!token) {
            token = uuidv4();
            TokenStorage.save(token);
            await http.post('/visits', {
                url: document.location.href,
                token: token,
                date: new Date().toISOString(),
                ip: ipInfo.IPv4
            })
        }
    }, []);

    return (
        <LanguageProvider>
            <Router>
                <Header />
                <HeaderMobile isOpen={isMobileMenuOpen} toggleMenu={setMobileMenuOpen} />
                <MobileMenu isOpen={isMobileMenuOpen} closeMenu={() => setMobileMenuOpen(false)} />
                <MainModule />
            </Router>
        </LanguageProvider>
    )
}

export default App;