import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LanguageSelectorMobile from '../../translation/LanguageSelectorMobile';
import { Text } from '../../translation/index';

import * as S from './styled';

const options = [
  {
    label: <Text tid="ROMANTIC FLIGHTS" />,
    id: 'romantic',
  },
  {
    label: <Text tid="RENTALS" />,
    id: 'rentals_mobile',
  },
  {
    label: <Text tid="TRAINING" />,
    id: 'training_mobile',
  },
  {
    label: <Text tid="ABOUT AIRCRAFT" />,
    id: 'about_aircraft_mobile',
  },
  {
    label: <Text tid="ABOUT US" />,
    id: 'about_us_mobile',
  }
];

const MenuItem = ({ label, active, handleActive, id, closeMenu, startId, }) => {
  const [anchorTarget, setAnchorTarget] = useState(null);

  const location = useLocation();
  const history = useHistory();

  const headerHeight = 96 + 0;

  useEffect(() => {
    setAnchorTarget(document.getElementById(id));
  }, [id, location]);

  const handleClick = (event, sectionId) => {
    if (location.pathname === '/thank') {
      closeMenu();
      history.push('/');
      return;
    }
    event.preventDefault();

    const top = anchorTarget.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    window.scrollTo({top: top, behavior: 'smooth'});
    closeMenu();
  };

  return <S.MenuItem onClick={(e) => handleClick(e, id)} selected={active === id && id !== startId}>{label}</S.MenuItem>
};

const MobileMenu = ({isOpen, closeMenu}) => {
  const [active, setActive] = useState(options[0].id);
  const handleActive = (id) => setActive(id);

  const padding = 101;

  const [heights, setHeights] = useState({});

  useEffect(() => {
    let obj = {};
    options.map(option => {
      obj[option.id] = document.getElementById(option.id)?.getBoundingClientRect()?.height;
    })
    setHeights(obj);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 0 && window.scrollY < (heights['romantic'] - padding)) {
        setActive(options[0].id)
      }
      if (window.scrollY >= (heights['romantic'] - padding) && window.scrollY < (heights['rentals_mobile'] + heights['romantic'] - padding)) {
        setActive(options[1].id);
      }
      if (window.scrollY >= (heights['rentals_mobile'] + heights['romantic'] - padding) && window.scrollY < (heights['rentals_mobile'] + heights['romantic'] + heights['about_aircraft_mobile'] - padding)) {
        setActive(options[2].id);
      }
      if (window.scrollY >= (heights['about_aircraft_mobile'] + heights['romantic'] + heights['rentals_mobile'] - padding) && window.scrollY < (heights['about_aircraft_mobile'] + heights['romantic'] + heights['rentals_mobile'] + 418 - padding)) {
        setActive(options[3].id);
      }
      if (window.scrollY >= (heights['about_aircraft_mobile'] + heights['romantic'] + heights['rentals_mobile'] + heights['about_us_mobile'] - padding)) {
        setActive(options[4].id);
      }
    });
  }, [heights]);
  console.log(heights);
  return (
    <S.Container className={isOpen ? "open" : ''}>
      <S.Menu>
        {
          options.map(option => (
            <MenuItem
              key={option.id}
              active={active}
              label={option.label}
              id={option.id}
              closeMenu={closeMenu}
              handleActive={handleActive}
              startId={options[0].id}
            />
          ))
        }
        <LanguageSelectorMobile />
      </S.Menu>
    </S.Container>
  )
}

export default MobileMenu;