import styled from 'styled-components';

export const Container = styled.div`
  width: 336px;
  height: 60px;
  background: #F2F2F2;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  /* flex-direction: ${({isRTL}) => isRTL ? 'row' : 'row-reverse'}; */
  align-items: center;
  position: relative;
  border-width: ${({error}) => error ? 1 : 0}px;
  border-color: ${({error}) => error ? '#E53C3C' : 'transparent'};
  border-style: solid;
`;

export const OptionsMenu = styled.div`
  width: 87px;
  height: 200px;
  background-color: #F2F2F2;
  position: absolute;
  bottom: -195px;
  overflow: scroll;
`;

export const Option = styled.div`
  cursor: pointer;
  margin: 5px 0;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  color: #293E67;
  padding-left: 16px;
  :hover {
    opacity: 0.5;
  }
`;

export const SelectValue = styled.div`
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #293E67;
  padding-left: 20px;
  /* padding-left: ${({isRTL}) => isRTL ? 20 : 20}px;
  padding-right: ${({isRTL}) => isRTL ? 0 : 20}px; */
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  background: transparent;
  border-width: 0;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #293E67;
  padding-left: 16px;
  /* text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
  padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
  padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px; */
  ::placeholder {
    font-family: Aeroport;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #293E67;
    opacity: 0.4;
  }
  @media (max-width: 960px) {
    font-size: 16px;
    ::placeholder {
      font-size: 16px;
    }
  }
`;

export const Error = styled.div`
  margin-top: 4px;
  color: #E53C3C;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media (max-width: 960px) {
    font-size: 12px;
  }
`;