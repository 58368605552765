import styled from 'styled-components';

export const Container = styled.input`
  width: 320px;
  height: 60px;
  background: #FFFFFF;
  outline: none;
  backdrop-filter: blur(14px);
  border-radius: 8px;
  border-width: ${({error}) => error ? 1 : 0}px;
  border-color: ${({error}) => error ? '#E53C3C' : 'transparent'};
  font-size: 24px;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  color: #293E67;

  padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
  padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px;
  text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
  ::placeholder {
    color: #293E67;
    opacity: 0.4;
  }
  @media (max-width: 960px) {
    font-size: 16px;
  }
`;

export const Error = styled.div`
  margin-top: 4px;
  color: #E53C3C;
  font-family: Aeroport;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media (max-width: 960px) {
    font-size: 12px;
  }
`;