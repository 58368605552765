import styled from 'styled-components';
import mobileBg from '../../assets/images/about-us.jpg';
import mobileBgRTL from '../../assets/images/about-us_rtl.jpg';

export const MainBlock = styled.div`
  position: absolute;
  top: 0;
  right: ${({isRTL}) => isRTL ? '50%' : 0};
  left: ${({isRTL}) => !isRTL ? '50%' : 0};
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ${({isRTL}) => !isRTL ? 100 : 0}px;
  padding-left: ${({isRTL}) => isRTL ? 100 : 0}px;
`;

export const Root = styled.section`
  width: 100%;
  height: 800px;
  position: relative;
  background-image: ${({bg}) => `url(${bg})`};
  background-size: cover;
  background-position: center center;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Overlay = styled.section`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: '100%';
  height: '100%';
  background: linear-gradient(-89.65deg, #71C2E5 0.29%, rgba(113, 194, 229, 0) 99.68%);
  mix-blend-mode: multiply;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  transform: ${({isRTL}) => isRTL ? 'matrix(-1, 0, 0, 1, 0, 0)' : 'none'};
`;

export const RootMobile = styled.div`
  display: none;
  width: 100%;
  background-image: ${({isRTL}) => isRTL ? `url(${mobileBg})` : `url(${mobileBgRTL})`};
  background-size: cover;
  /* background-position: 80%; */
  background-position: ${({isRTL}) => isRTL ? `80%` : `30%`};
  @media (max-width: 1200px) {
     display: block;
  }
`;

export const ContentContainerMobile = styled.div`
  display: none;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(113, 194, 229, 0.5);
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const TitleMobile = styled.span`
    font-family: History Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-decoration-line: underline;
    color: #FFFFFF;;
    margin: 20px 0px;
    /* padding-left: 16px;
    padding-right: 16px; */
    padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
    padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px;
    text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
`;

export const DescriptionMobile = styled.span`
    font-family: Aeroport;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    /* padding-left: 16px;
    padding-right: 16px; */
    padding-left: ${({isRTL}) => isRTL ? 16 : 0}px;
    padding-right: ${({isRTL}) => !isRTL ? 16 : 0}px;
    text-align: ${({isRTL}) => isRTL ? 'left' : 'right'};
    direction: ${({isRTL}) => isRTL ? 'ltr' : 'rtl'};
`;